import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'corenav/coreNavigation.css';
import 'corenav/coreNavigation';

// (function($) {
//
//   console.log('jQuery is Loaded!');
//
//
//
// })(jQuery);

